@import "font-face";

@include font-face(
  "montserrat",
  "/fonts/Montserrat/Montserrat-Medium",
  500,
  normal
);
@include font-face(
  "montserrat",
  "/fonts/Montserrat/Montserrat-SemiBold",
  600,
  normal
);
@include font-face(
  "montserrat",
  "/fonts/Montserrat/Montserrat-Bold",
  bold,
  normal
);

.list-style_initial {
  list-style: initial;
}
